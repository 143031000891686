import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import { gql, useQuery } from "@apollo/client"
import ReactHtmlParser from "react-html-parser"
import CallToAction from "../components/global/CallToAction"

const NewsletterPost = pageData => {
  const id = pageData.pageContext.id

  const newletterContainer = {
    maxWidth: "620px",
    minHeight: "400px",
    borderRadius: "6px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#0C3A2F",
  }

  const callToActionStyling = {
    padding: "0",
  }

  const GET_SINGLE_SEND = gql`
    query singleSends($id: String!) {
      singleSend(id: $id) {
        id
        name
        email_config {
          html_content
        }
      }
    }
  `

  const { loading, error, data } = useQuery(GET_SINGLE_SEND, {
    variables: {
      id: id,
    },
  })

  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`

  const htmlContent = data["singleSend"]["email_config"]["html_content"]

  return (
    <Layout bodyTheme="theme-blog-dark">
      <header>
        <Menu />
      </header>
      <main className="page-wrapper newsletter-item-wrapper">
        <section className="section section-first">
          <div style={newletterContainer}>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <div className="section-heading-text">
                    <h1>{data["singleSend"]["name"]}</h1>
                  </div>
                </div>
              </div>
            </div>
            {ReactHtmlParser(htmlContent)}
            <div className="col-lg-12">
              <CallToAction />
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </Layout>
  )
}

export default NewsletterPost
